.App {
  text-align: center;
  font-family: system-ui;
}

body {
  margin: 0;
  padding: 0;
  font-family: system-ui;
  
}

a {
  text-decoration: none;
  color: #000;
}